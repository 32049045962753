import React from "react";
import {
  PaymentClientType,
  PaymentFormChild,
  PaymentFormService,
} from "../../cfiFormResult";
import { ExtendedTable } from "@torqit/crew-2ls";
import { usePaymentRequestSummaryColumns } from "./usePaymentRequestSummaryColumns";

export interface PaymentRequestSummaryProps {
  clientType?: PaymentClientType;
  services?: PaymentFormService[];
  children?: PaymentFormChild[];
}

export function PaymentRequestSummary({
  clientType,
  services,
  children,
}: PaymentRequestSummaryProps) {
  const columns = usePaymentRequestSummaryColumns(children, clientType);

  return (
    <ExtendedTable
      data={services}
      columns={columns}
      getDataKey={(s) => s.service}
    />
  );
}
