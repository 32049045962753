import { UrgencyType } from "./Applicant/RequestUrgency";
import { ContactPreference } from "./Applicant/ApplicantInfo";
import { Dayjs } from "dayjs";
import { RelationshipOptions } from "./Applicant/RelationSelector";

type YesNo = "Yes" | "No";

export interface CFIChild extends Partial<Address> {
  id: number;
  firstName: string;
  middleName?: string;
  lastName: string;
  gender: string;
  dateOfBirth: string;
  childNumberType: string;
  childNumber?: string;
  parent?: {
    numberType: string;
    number: string;
    name: string;
  };
}

export const payors = ["organization", "family", "serviceProvider"] as const;
export type Payor = (typeof payors)[number];

export interface CFIService {
  children?: number[];
  service: string;
  serviceNeed?: string;
  customService?: string;
  serviceType: string;
  fundingReason?: string;
  customFundingReason?: string;
  requestContext?: string;
  requestNeed?: string;
  firstTimeRequest?: YesNo;
  stepsTakenSinceLastRequest?: string;
  frequency: number;
  cost: number;
  payor: Payor;
  customPayor?: string;
  startDate?: string;
  endDate?: string;
}

interface BaseResult {
  applicationType:
    | "personal"
    | "oneTimeProject"
    | "structuringProject"
    | "payment";
}

interface BaseCFIFormResult extends BaseResult {
  hasBeneficiaryNumbers: YesNo;
  underAgeOfMajority: YesNo;
  hasExploredFunding: YesNo;
  hasDocuments: YesNo;
  requestUrgency: UrgencyType;
  hasParentalConsentOrAuthority: boolean;
  hasInformedOrganization: boolean;
  relationship: RelationshipOptions | "other";
  customRelationship: string;
  applicant: {
    organizationLocation: string;
    organization: string;
    customOrganization?: string;
    firstName: string;
    lastName: string;
    title?: string;
    telephone: string;
    email: string;
    preferredContact: ContactPreference;
  } & Address;
  contact?: ApplicationContact;
  isApplicantContact: YesNo;
  overTenChildren: YesNo;
  childrenLiveAtSameAddress?: YesNo;
  childrenAddress: Partial<Address> & { sameAddressAsApplicant?: boolean };
  children: CFIChild[];
  finishedAddingChildren: boolean;
  firstTimeRequest: YesNo;
  fundingReason?: string;
  customFundingReason?: string;
  requestContext?: string;
  stepsTakenSinceLastRequest?: string;
  services: CFIService[];
  providingDocuments: boolean;
  finishedAddingServices: boolean;
  documents: {
    parentalAuthorization?: File[];
    consentForm?: File[];
    recommendationLetter?: File[];
    evaluationDiagnosis?: File[];
    serviceQuotes?: File[];
    invoices?: File[];
    other?: File[];
  };
  documentComments?: string;
  finishedAddingDocuments: boolean;
  finalConsent: boolean;
  date: string;
  signature: string;
}

interface Address {
  mailingAddress: string;
  community: string;
  provinceTerritory: string;
  postalCode: string;
}

export interface ApplicationContact {
  firstName: string;
  lastName: string;
  title?: string;
  telephone: string;
  email: string;
  preferredContact: ContactPreference;
}

export interface PersonalCFIFormResult extends BaseCFIFormResult {
  applicationType: "personal";
}

export interface ProjectCFIFormResult extends BaseCFIFormResult {
  applicationType: "oneTimeProject" | "structuringProject";
  approximateChildCount: number;
  minChildAge: number;
  maxChildAge: number;
  projectStartDate: string;
  projectEndDate?: string;
  projectInNunavik: YesNo;
  projectLocation: string;
  existingRequestFileNumber: string;
}

export type CFIFormResult = PersonalCFIFormResult | ProjectCFIFormResult;

export interface PaymentFormChild {
  id: number;
  firstName: string;
  lastName: string;
  middleName?: string;
  dateOfBirth: string;
}

export interface PaymentFormService {
  service: string;
  customService: string;
  children?: number[];
  cost: number;
}

export type PaymentClientType =
  | "personal"
  | "oneTimeProject"
  | "structuringProject";

export interface PaymentFormResult extends BaseResult {
  applicationType: "payment";
  hasDocuments: YesNo;
  clientType: PaymentClientType;
  approvedFileNumber: string;
  children?: PaymentFormChild[];
  finishedAddingChildren: boolean;
  applicant: {
    relationship: Payor;
    customRelationship: string;
    firstName: string;
    lastName: string;
    title?: string;
    telephone: string;
    email: string;
    preferredContact: ContactPreference;
  };
  services: PaymentFormService[];
  finishedAddingServices: boolean;
  addressInfo: {
    mailingAddress: string;
    community: string;
    provinceTerritory: string;
    postalCode: string;
  };
  firstTimeRequest: YesNo;
  changedAddress: YesNo;
  preferredPaymentMethod: "cheque" | "directDeposit";
  changedBankAccount: YesNo;
  paymentNotificationEmail: string;
  hasVoidCheque: YesNo;
  bankingInfo?: {
    bankNumber: string;
    branchNumber: string;
    accountNumber: string;
    bankName: string;
  };
  documents: {
    voidCheque?: File[];
    invoices?: File[];
    other?: File[];
  };
  finalConsent: boolean;
  finishedAddingDocuments: boolean;
  date: string;
  signature: string;
}

export type FormResult = CFIFormResult | PaymentFormResult;
